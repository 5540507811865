import { useState } from "react";
import PhoneInput from "react-phone-input-2";

import "react-phone-input-2/lib/style.css";
import styles from "./style.module.css";
import SaveCancelButton from "../../../../components/SaveCancelButtons";
import withErrorBoundary from "../../../../components/ErrorBoundary";

type PhoneNumberProps = {
  value: string;
  onSave: (value: string) => void;
  editMode: boolean;
  disableEditMode: () => void;
};

const PhoneNumber = ({
  value,
  onSave,
  editMode,
  disableEditMode,
}: PhoneNumberProps) => {
  const [editedValue, setEditedValue] = useState(value);

  // TODO: acount for mobile and landline numbers
  return (
    <div className={`${styles.container} ${editMode ? "" : styles.display}`}>
      {editMode ? (
        <div>
          <div className={styles.input}>
            <PhoneInput
              countryCodeEditable={false}
              country={"hu"}
              preferredCountries={["hu"]}
              masks={{ hu: "(..) ...-...." }}
              value={editedValue}
              onChange={(val: string) => {
                console.log("val", val);
                setEditedValue(val);
              }}
            />
          </div>
          <SaveCancelButton
            onSave={() => {
              onSave(editedValue);
              disableEditMode();
            }}
            onCancel={() => {
              disableEditMode();
              setEditedValue(value);
            }}
            disabled={editedValue === value}
          />
        </div>
      ) : (
        <div className={styles.phoneNumber}>{editedValue}</div>
      )}
    </div>
  );
};

export default withErrorBoundary(PhoneNumber);
