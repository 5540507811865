import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore/lite";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
import {
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  sendPasswordResetEmail as _sendPasswordResetEmail,
} from "firebase/auth";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { firebaseConfig, isProd } from "../utils/envConfig";

const useFirebaseEmulators =
  process.env.REACT_APP_USE_FIREBASE_EMULATORS === "true";

export interface FirebaseDefaults {
  config?: Record<string, string>;
  emulatorHosts?: Record<string, string>;
  _authTokenSyncURL?: string;
  _authIdTokenMaxAge?: number;
  forceEnvironment?: "browser" | "node";
  [key: string]: unknown;
}

declare global {
  // Need `var` for this to work.
  // eslint-disable-next-line no-var
  var __FIREBASE_DEFAULTS__: FirebaseDefaults | undefined;
}

if (useFirebaseEmulators) {
  globalThis.__FIREBASE_DEFAULTS__ = {
    emulatorHosts: {
      functions: "127.0.0.1:5001",
      firestore: "127.0.0.1:8081",
    },
    forceEnvironment: "browser",
  };
}

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

const storage = getStorage(app);

const functions = getFunctions(app, "europe-west1");


window.FIREBASE_APPCHECK_DEBUG_TOKEN = !isProd;

export const auth = getAuth(app);
export const sendPasswordResetEmail = _sendPasswordResetEmail;
export const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider("6Lfa9RcpAAAAAJbzi23O4l1PoFVFaS8NborL0lLy"),
  isTokenAutoRefreshEnabled: true,
});

export const googleAuthProvider = new GoogleAuthProvider();
export const facebookAuthProvider = new FacebookAuthProvider();

export { app, db, functions, storage };
export default app;
