import { useQuery, useMutation } from "react-query";
import getAvailableTemplates from "../../api/getAvailableTemplates";

import useUser from "../../hooks/useUser";
import updateUserTemplateId from "../../api/updateUserTemplateId";
import TemplateCard from "../TemplateCard";
// import { Select } from "antd";
import "./style.css";
import withErrorBoundary from "../ErrorBoundary";

const TemplatePicker = () => {
  const { uid, templateId, refetch, user } = useUser();

  const { data } = useQuery(
    `templatePicker-gym`,
    getAvailableTemplates("gym"),
    // These lines can be uncommented once more templates are available
    // `templatePicker-${user?.selectedBusiness}`,
    // getAvailableTemplates(user?.selectedBusiness),
    {
      enabled: !!user,
    }
  );

  const userTemplateMutation = useMutation(
    ({
      userId,
      template,
      selectedBusiness,
    }: {
      userId: string;
      template: string | null;
      selectedBusiness?: string;
    }) => {
      return updateUserTemplateId(userId, template, selectedBusiness);
    },
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  return (
    <div className="template-picker">
      <div className="section-title">Válaszd ki a sablonod</div>
      
      {/* We can remove the comment once more templates are aviailable 
      <div className="section-title">
        Sablon típus:
        <Select
          value={user?.selectedBusiness}
          onChange={(value) => {
            userTemplateMutation.mutate({
              userId: uid,
              template: null,
              selectedBusiness: value,
            });
          }}
          options={[
            { value: "massage", label: "Masszázs" },
            { value: "gym", label: "Edzőterem" },
          ]}
        />
      </div> */}

      <div className="templates-container">
        {data
          ? data.map(({ id, name, promoPics, exampleURL }) => {
              return (
                <TemplateCard
                  key={id}
                  id={id}
                  name={name}
                  pictures={promoPics}
                  exampleURL={exampleURL}
                  isSelected={templateId === id}
                  onSelect={() =>
                    userTemplateMutation.mutate({
                      userId: uid,
                      template: id,
                    })
                  }
                />
              );
            })
          : null}
      </div>
    </div>
  );
};

export default withErrorBoundary(TemplatePicker);
