import useUser from "./useUser";
import useTemplate from "./useTemplate";

const useMergeUserAndDefaultTemplateForPreview = () => {
  const { user, templateId } = useUser();
  const { template } = useTemplate();
  const templateData = template?.data;
  const userTemplate = user?.templates?.[templateId];
  if (userTemplate && templateData) {
    return Object.entries(templateData).reduce((acc, [key, value]) => {
      if (userTemplate[key]) {
        return { ...acc, [key]: userTemplate[key] };
      }
      return { ...acc, [key]: value.default };
    }, {});
  }

  return [];
};

export default useMergeUserAndDefaultTemplateForPreview;
