import { useEffect, useRef } from "react";
import { Link, Outlet } from "react-router-dom";
import { AppCheckTokenResult, getToken } from "firebase/app-check";
import { Button } from "antd";

import useTemplate from "../../hooks/useTemplate";
import { appCheck } from "../../api/firebaseConfig";

import withErrorBoundary from "../../components/ErrorBoundary";

import "./style.css";

const Dashboard = () => {
  const { template /* templateId */ } = useTemplate();
  const tokenRef = useRef<AppCheckTokenResult>();
  const pages = template?.pages;

  useEffect(() => {
    async function run() {
      const token = await getToken(appCheck);

      tokenRef.current = token;
    }

    run();
  }, []);

  return (
    <div className="dashboard">
      <div className="dashboard-nav">
        <div className="dashboard-menu dashboard-menu-item">
          <Link to={``}>
            <Button
              type="default"
              size="large"
              className="website-settings-button"
            >
              Főoldal
            </Button>
          </Link>
          <Link to={`settings`}>
            <Button
              type="default"
              size="large"
              className="website-settings-button"
            >
              Weboldal beállítások
            </Button>
          </Link>
        </div>
        <div className="dashboard-nav-title">Weboldal konfigurálása</div>
        {pages &&
          Object.keys(pages).map((key: any) => (
            <div
              key={`dashboard-template-${key}`}
              className="dashboard-menu dashboard-menu-item-sub"
            >
              <Link to={`template/${pages[key].id}`}>
                <Button
                  type="default"
                  size="large"
                  className="page-section-button"
                >
                  {pages[key].name}
                </Button>
              </Link>
            </div>
          ))}
      </div>
      <div className="dashboard-content">
        <Outlet />
      </div>
    </div>
  );
};

export default withErrorBoundary(Dashboard);
