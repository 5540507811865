import { useState } from "react";
import {
  auth,
  googleAuthProvider,
  facebookAuthProvider,
} from "../../../api/firebaseConfig";
import { signInWithPopup } from "firebase/auth";
import { createUserWithEmailAndPassword } from "firebase/auth";
import "./style.css";
import LargeButton from "../LargeButton";
import { parseFirebaseAuthErrorMessage } from "../../../utils/helpers";

function RegisterModal({ onClose, switchAuthMode }: any) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [registerPending, setRegisterPending] = useState(false);
  const [error, setError] = useState("");

  const handleEmailChange = (event: any) => {
    setEmail(event.target.value);
    setError("");
  };
  
  const handlePasswordChange = (event: any) => {
    setPassword(event.target.value);
    setError("");
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    setRegisterPending(true);
    await createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        onClose();
        setRegisterPending(false);
      })
      .catch((error) => {
        setRegisterPending(false);
        setError(parseFirebaseAuthErrorMessage(error));
      });
  };

  const signInWithGoogle = (event: any) => {
    event.preventDefault();

    signInWithPopup(auth, googleAuthProvider)
      .then(() => {
        console.log("Logged in");
        onClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const signInWithFacebook = (event: any) => {
    event.preventDefault();

    signInWithPopup(auth, facebookAuthProvider)
      .then(() => {
        console.log("Logged in");
        onClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="register-form">
      <div className="header">
        <span className="title">Regisztráció</span>
        <div>
          <div className="switch-mode" onClick={() => switchAuthMode("login")}>
            <span className="switch-mode-text">Már regisztráltál? </span>
            Bejelentkezés
          </div>
        </div>
      </div>
      <div className="input-group">
        <label htmlFor="email" className="label">
          Email cím
        </label>
        <input
          id="email"
          name="email"
          type="email"
          onChange={handleEmailChange}
          required
          className={`input ${error && "error"}`}
        />
        <div className="error-box">{error}</div>
      </div>
      <div className="input-group password">
        <label htmlFor="password" className="label">
          Jelszó
        </label>
        <input
          id="password"
          name="password"
          type="password"
          autoComplete="current-password"
          onChange={handlePasswordChange}
          required
          className={`input ${error && "error"}`}
        />
      </div>
      <LargeButton
        text="Regisztráció"
        onClick={handleSubmit}
        disabled={!((email.length && password.length) || registerPending)}
        isLoading={registerPending}
      />
      <div className="social-buttons">
        <button className="social-button" onClick={signInWithGoogle}>
          <img
            src={process.env.PUBLIC_URL + "/assets/google.svg"}
            alt="Google"
            className="social-icon"
          />
          Regisztráció Google fiókkal
        </button>
        <button className="social-button" onClick={signInWithFacebook}>
          <img
            src={process.env.PUBLIC_URL + "/assets/facebook.svg"}
            alt="Facebook"
            className="social-icon"
          />
          Regisztráció Facebook fiókkal
        </button>
      </div>
    </div>
  );
}

export default RegisterModal;
