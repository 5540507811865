import styles from "./style.module.css";
import withErrorBoundary from "../ErrorBoundary";

type Props = {
  characterLimit?: number;
  characterCount: number;
};

const CharacterCount = ({ characterLimit, characterCount }: Props) => {
  return (
    <div className={styles.characterLimit}>
      {characterLimit && ( characterCount + "/" + characterLimit)}
    </div>
  );
};

export default withErrorBoundary(CharacterCount);
