import styles from "./style.module.css";
import withErrorBoundary from "../../components/ErrorBoundary";

const WelcomeToTheDashboard = () => {
  return (
    <div className="">
      <h1>Üdvözöllek a vezérlőpulton</h1>
      <div>
        <p className={styles.p}>
          A következő pár mondatban összefoglaljuk, hogy hogyan tudod használni
          a szolgáltatást. A teljes beállítási folyamat 5-10 percet vesz
          igénybe, melynek végén a saját címeden elérhető lesz a weboldalad.
        </p>
        <p className={styles.p}>
          A bal oldali menüben található "Weboldal beállítások" gombra kattintva
          tudod a legfontosabb beállításokat megtenni. Mindenekelőtt válassz egy
          neked tetsző nevet. Weboldalad a <b>www.neved.webgen.hu</b> néven lesz
          elérhető.
          <b>
            Vigyázz, mert ezt a nevet később nem tudod megváltoztatni. Válassz
            körültekintően!
          </b>
        </p>
        <p className={styles.p}>
          Ezek után válassz egy témát, amely a weboldalad kinézetét, felépítését
          fogja meghatározni. Kiválasztás után fel fogjuk tölteni a témát
          alapértelmezett szövegekkel, képekkel, hogy lásd, hogy hogy fog
          kinézni a weboldalad ha végeztél.
        </p>
        <p className={styles.p}>
          A kiválasztás után bal oldalon elérhetővé válik a sablonhoz tartozó
          összes oldal neve a "Weboldal konfigurálása" menüpont alatt. Ezen
          oldalakon tudod konfigurálni a weboldalad tartalmát. Kérlek
          figyelmesen változtass meg minden szöveget, képet, hogy a te
          szolgáltatásodat tükrözze. Az első oldalt leszámítva, ahol általános
          kérdésekre kell válaszolnod, mindenhol láthatod, hogy fog kinézni a
          kész oldalad.
        </p>
        <p className={styles.p}>
          Ha végeztél mindennel, a "Weboldal beállítások" pontban a "weboldal
          közzététele" gombra kattintva tudod elérhetővé tenni a weboldalad. Ez
          eltarthat néhány percig, amíg a szerverek frissítik magukat, de utána
          már elérhető lesz a weboldalad a saját címeden. Ha bármikor
          változtatni szeretnél a weboldalad tartalmán, a módosítások után ismét
          ezt a gombot kell megnyomnod, hogy a változtatások érvénybe lépjenek.
        </p>
      </div>
    </div>
  );
};

export default withErrorBoundary(WelcomeToTheDashboard);
