import { Select as SelectComponent } from "antd";
import withErrorBoundary from "../../../../components/ErrorBoundary";

type SelectProps = {
  data: string;
  inputRules: {
    options: string[];
  };
  value?: string;
  refreshPreview: () => void;
  onSave: (value: string) => void;
  isSavedSeparately: boolean;
  changeValueInParent: (value: string) => void;
};

const Select = ({ data, inputRules, value, onSave }: SelectProps) => {
  return (
    <SelectComponent
      value={value}
      style={{ width: 120 }}
      onChange={onSave}
      options={inputRules.options.map((option) => ({
        value: option,
        label: option,
      }))}
    />
  );
};

export default withErrorBoundary(Select);
