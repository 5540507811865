import { Button } from "antd";
import styles from "./style.module.css";
import withErrorBoundary from "../ErrorBoundary";

type Props = {
  onClick: () => void;
  className?: string;
  text?: string;
  icon?: any
};

const EditButton = ({ onClick, className, text="Szerkesztés", icon=undefined }: Props) => {
  return (
    <Button className={`${styles.editButton} ${className}`} onClick={onClick} icon={icon}>
      {text}
    </Button>
  );
};

export default withErrorBoundary(EditButton);
