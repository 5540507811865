import { db } from "./firebaseConfig";
import { collection, getDocs, query, where } from "firebase/firestore/lite";

const getAvailableTemplates = (businessCategory: string) => async () => {
  const collectionInstance = collection(db, "templates");
  const q = query(
    collectionInstance,
    where("businessCategory", "==", businessCategory)
  );
  const snapshot = await getDocs(q);
  const result = snapshot.docs.map((doc) => ({
    id: doc.id,
    name: doc.data().name,
    promoPics: doc.data().promoPics as string[],
    exampleURL: doc.data().exampleURL,
  }));

  return result;
};

export default getAvailableTemplates;
//select("Order", "Amount")
