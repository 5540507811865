import { Checkbox as CheckboxComponent } from "antd";
import withErrorBoundary from "../../../../components/ErrorBoundary";

type CheckboxProps = {
  data: string;
  inputRules: {
    label: string;
  };
  value?: boolean;
  refreshPreview: () => void;
  onSave: (value: boolean) => void;
  isSavedSeparately: boolean;
  changeValueInParent: (value: string) => void;
};

const Checkbox = ({ inputRules, value, onSave }: CheckboxProps) => {
  return (
    <CheckboxComponent
      checked={value}
      onChange={(e) => onSave(e.target.checked)}
    >
      {inputRules.label}
    </CheckboxComponent>
  );
};

export default withErrorBoundary(Checkbox);
