import React, { useCallback, useEffect, useState } from "react";
import TemplateWrapper from "../../TemplateWrapper";
import TextArea from "../TextArea";
import PhoneNumber from "../PhoneNumber";
import TextInput from "../TextInput";
import Image from "../Image";
import mergeUserAndDefaultTemplateList from "../../../../utils/mergeUserAndDefaultTemplateList";
import { v4 } from "uuid";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import SaveCancelButton from "../../../../components/SaveCancelButtons";
import CancelButton from "../../../../components/SaveCancelButtons/CancelButton";

import styles from "./style.module.css";
import withErrorBoundary from "../../../../components/ErrorBoundary";

const segments = {
  textArea: TextArea,
  phoneNumber: PhoneNumber,
  textInput: TextInput,
  image: Image,
};

const ListElementEditable = React.memo(
  ({ sectionData, removeHandler, onChange, index, data, dataOrder }: any) => {
    // if there is no dataOrder, then use the default order
    if (!dataOrder) {
      dataOrder = sectionData.data.map((data: any) => data.id);
    }
    return (
      <div key={sectionData.dataId}>
        {dataOrder?.map((segment: any) => {
          const segmentData = sectionData.data.find(
            (data: any) => data.id === segment
          );
          return (
            <TemplateWrapper
              key={segmentData.id}
              type={segmentData.type}
              name={segmentData.title}
              value={segmentData.value}
              segments={segments}
              isSavedSeparately={false}
              changeValueInParent={(value: any) =>
                onChange(segmentData.id, index, value)
              }
              inputRules={segmentData.inputRules}
              data={{ ...data, segmentId: sectionData.dataId }}
            ></TemplateWrapper>
          );
        })}
        <div className={styles.deleteElementButtonContainer}>
          <CancelButton
            onClick={() => removeHandler(index)}
            text={"Elem törlése"}
          />
        </div>
      </div>
    );
  }
);

const ListElement = ({ sectionData, dataOrder }: any) => {
  // if there is no dataOrder, then use the default order
  if (!dataOrder) {
    dataOrder = sectionData.data.map((data: any) => data.id);
  }
  return (
    <div key={sectionData.dataId}>
      {dataOrder?.map((segment: any, index: number) => {
        const segmentData = sectionData.data.find(
          (data: any) => data.id === segment
        );
        return (
          <div key={index}>
            <div className={styles.titleUneditable}>{segmentData.title}</div>
            {segmentData.type === "image" ? (
              <img
                alt=""
                src={segmentData.value}
                className={styles.imageField}
              />
            ) : (
              <div className={styles.valueUneditable}>{segmentData.value}</div>
            )}
          </div>
        );
      })}
    </div>
  );
};

const List = ({
  value: inputValue,
  dataSegments,
  onSave,
  data,
  editMode,
  disableEditMode,
  dataOrder,
}: any) => {
  const [editedValue, setEditedValue]: any = useState(
    inputValue ? [...inputValue] : []
  );

  const [list, setList]: any = useState(
    mergeUserAndDefaultTemplateList(dataSegments, inputValue || [])
  );

  useEffect(() => {
    setList(mergeUserAndDefaultTemplateList(dataSegments, editedValue || []));
  }, [editedValue, dataSegments]);

  const addToList = () => {
    const newList = editedValue ? [...editedValue] : [];

    newList.push({ dataId: v4() });
    setEditedValue(newList);
  };

  const onValueChange = (segmentId: string, index: number, value: string) => {
    const newList = [...editedValue];
    newList[index][segmentId] = value;

    setEditedValue(newList);
  };

  const saveData = () => {
    onSave(editedValue);
    disableEditMode();
  };

  const removeFromList = useCallback(
    (val: number): any => {
      const filteredValue = editedValue.filter(
        (_: any, i: number) => i !== val
      );

      setEditedValue(filteredValue);
    },
    [editedValue]
  );

  return (
    <>
      {editMode ? (
        <>
          {list.map((sectionData: any, i: number) => (
            <div className={styles.listElement} key={i}>
              <ListElementEditable
                sectionData={sectionData}
                removeHandler={removeFromList}
                index={i}
                onChange={onValueChange}
                data={data}
                dataOrder={dataOrder}
              />
            </div>
          ))}
          <Button onClick={addToList} className={styles.addNewElementButton}>
            <PlusOutlined className={styles.addNewIcon} /> Új elem hozzáadása
          </Button>
          <div className={styles.saveButtonDiv}>
            <SaveCancelButton onSave={saveData} onCancel={disableEditMode} />
          </div>
        </>
      ) : (
        list.map((sectionData: any, index: number) => (
          <div className={styles.listElement} key={index}>
            <ListElement sectionData={sectionData} dataOrder={dataOrder} />
          </div>
        ))
      )}
    </>
  );
};

export default withErrorBoundary(List);
