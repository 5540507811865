import { db } from "./firebaseConfig";
import { setDoc, doc } from "firebase/firestore/lite";
import getUserDetails from "./getUserDetails";
import getTemplate from "./getTemplate";
import { v4 } from "uuid";

const hasTemplate = (user: any, templateId: string) =>
  user?.templates?.[templateId] || false;

const getDefaults = (template: any): any =>
  Object.keys(template).reduce((acc: any, key: any) => {
    const value = template[key];

    if (value.type === "list") {
      return { ...acc, [key]: [{ dataId: v4(), ...getDefaults(value.data) }] };
    }
    return { ...acc, [key]: value.default };
  }, {});

const updateUserTemplateId = async (
  userId: string,
  templateId: string | null,
  selectedBusiness?: string
) => {
  console.log({ userId, templateId, selectedBusiness });
  if (templateId === null) {
    return setDoc(
      doc(db, "users", userId),
      {
        template: null,
        selectedBusiness,
      },
      { merge: true }
    );
  }
  const user = await getUserDetails(userId)();
  if (!hasTemplate(user, templateId)) {
    const template = (await getTemplate(templateId)()) as any;
    const templateData = template?.data;
    const defaultUserData = getDefaults(templateData);
    return setDoc(
      doc(db, "users", userId),
      {
        template: templateId,
        templates: { [templateId]: defaultUserData },
      },
      { merge: true }
    );
  }
  return setDoc(
    doc(db, "users", userId),
    {
      template: templateId,
    },
    { merge: true }
  );
};

export default updateUserTemplateId;
