const isProd = process.env.NODE_ENV === "production";

const firebaseConfig = isProd
  ? // production DB
    {
      apiKey: "AIzaSyBZbRCIV1rvOyO0YJLuaa-NTsMIX14JyWk",
      authDomain: "massage-af8e1.firebaseapp.com",
      projectId: "massage-af8e1",
      storageBucket: "massage-af8e1.appspot.com",
      messagingSenderId: "269300582608",
      appId: "1:269300582608:web:c2a4f727adb731702836d5",
      measurementId: "G-R5CBBXCG7G",
    }
  : // development DB
    {
      apiKey: "AIzaSyDZEGGlmwjwGLwzXtuU1vhTc4m4iqxbwbE",
      authDomain: "webgen-dev-c21a8.firebaseapp.com",
      projectId: "webgen-dev-c21a8",
      storageBucket: "webgen-dev-c21a8.appspot.com",
      messagingSenderId: "60967259284",
      appId: "1:60967259284:web:971ec4a70343d81130b881",
      measurementId: "G-8YRG4CY4MF",
    };

const shouldDevbarBeShown = !isProd;

const isAllowedSiteName = (siteName: string) => {
  const isProd = process.env.NODE_ENV === "production";
  const startsWithWebgenDev = siteName.toLowerCase().startsWith("webgen-dev-");

  if (isProd && startsWithWebgenDev) {
    return false; // Not allowed in production if it starts with webgen-dev
  }

  if (!isProd && !startsWithWebgenDev) {
    return false; // Not allowed in development if it doesn't start with webgen-dev
  }

  return true;
};

const getPreviewURL = (templateId: string) =>
  isProd
    ? `https://${templateId?.toLowerCase()}.webgen.hu`
    : "http://localhost:3001";

export {
  isProd,
  firebaseConfig,
  shouldDevbarBeShown,
  isAllowedSiteName,
  getPreviewURL,
};
