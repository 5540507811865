import { useState } from "react";
import { Select, Input, Button } from "antd";
import withErrorBoundary from "../../../../components/ErrorBoundary";

type FormProps = {
  data: string;
  inputRules: {
    aspectRatio: {
      width: number;
      height: number;
    };
  };
  value: {
    selectedType?: string;
    value?: string | string[];
  };
  refreshPreview: () => void;
  onSave: (value: { selectedType?: string; value?: string | string[] }) => void;
  isSavedSeparately: boolean;
  changeValueInParent: (value: string) => void;
};

const renderSimpleTitleInput = () => {
  return (
    <>
      Kérdés: <Input></Input>
    </>
  );
};
const MultipleOptionsInput = () => {
  const [options, setOptions] = useState<string[]>([]);
  const [newOption, setNewOption] = useState<string>("");
  const [addingNewOption, setAddingNewOption] = useState<boolean>(false);

  const addOption = () => {
    setOptions([...options, newOption]);
    setNewOption("");
    setAddingNewOption(false);
  };

  return (
    <div>
      {options.map((option: string, index: number) => (
        <div key={index}>
          <span>{index}. opció</span>
          <span>{option}</span>
        </div>
      ))}
      {addingNewOption ? (
        <Input
          value={newOption}
          onChange={(e) => setNewOption(e.target.value)}
        ></Input>
      ) : null}
      {!addingNewOption ? (
        <Button onClick={() => setAddingNewOption(true)}>+</Button>
      ) : null}
      {addingNewOption && newOption ? (
        <Button onClick={addOption}>Mentés</Button>
      ) : null}
    </div>
  );
};

const Form = ({
  data,
  inputRules,
  value,
  refreshPreview,
  isSavedSeparately,
  changeValueInParent,
  onSave,
}: FormProps) => {
  const renderSelectedType = (type: string) => {
    switch (type) {
      case "textInput":
        return renderSimpleTitleInput();
      case "radioButtons":
        return <MultipleOptionsInput />;
      case "select":
        return <MultipleOptionsInput />;
      case "checkbox":
        return renderSimpleTitleInput();
      case "textArea":
        return renderSimpleTitleInput();
      default:
        return null;
    }
  };

  return (
    <>
      <Select
        disabled
        value={value?.selectedType}
        style={{ width: 150 }}
        options={[
          {
            value: "textInput",
            label: "Szöveges mező",
          },
          {
            value: "radioButtons",
            label: "Radio gombok",
          },
          {
            value: "select",
            label: "Legördülő menü",
          },
          {
            value: "checkbox",
            label: "Eldöntendő kérdés",
          },
          {
            value: "textArea",
            label: "Szövegdoboz",
          },
        ]}
        onChange={(val) => onSave({ ...value, selectedType: val })}
      ></Select>
      {value?.selectedType ? renderSelectedType(value.selectedType) : null}
    </>
  );
};

export default withErrorBoundary(Form);
