import Resizer from "react-image-file-resizer";

const resizeFile = (file: any, width: number, height: number) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      width,
      height,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

const resizeImage = async (
  file: HTMLCanvasElement,
  width: number,
  height: number
) => {
  return await new Promise((resolve) => {
    file.toBlob(
      async (blob) => {
        await resizeFile(blob, width, height).then(async (resized) => {
          resolve(resized);
        });
      },
      "image/jpeg",
      1
    );
  });
};

export default resizeImage;
