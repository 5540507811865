import { useState } from "react";
import { Input } from "antd";
import SaveCancelButton from "../../../../components/SaveCancelButtons";
import CharacterCount from "../../../../components/CharacterCount";
import withErrorBoundary from "../../../../components/ErrorBoundary";

import styles from "./style.module.css";

type TextAreaProps = {
  value: string;
  maxLength?: number;
  onSave: (value: string) => void;
  editMode: boolean;
  disableEditMode: () => void;
  isSavedSeparately: boolean;
  changeValueInParent: (value: string) => void;
};

const TextArea = ({
  onSave,
  value,
  maxLength,
  editMode,
  disableEditMode,
  isSavedSeparately,
  changeValueInParent,
}: TextAreaProps) => {
  const { TextArea } = Input;

  const [editedValue, setEditedValue] = useState(value);
  const handleOnChange = (e: any) => {
    const val = e.target.value;
    if (!maxLength || val.length <= maxLength) {
      setEditedValue(val);

      if (!isSavedSeparately) {
        changeValueInParent(val);
      }
    }
  };

  return editMode ? (
    <div className={styles.inputContainer}>
      <TextArea
        size="large"
        value={editedValue}
        onChange={handleOnChange}
        rows={4}
        placeholder="Itt adhatod meg a szöveget"
        onPressEnter={() => {
          onSave(editedValue);
          disableEditMode();
        }}
      />
      <CharacterCount
        characterCount={editedValue.length}
        characterLimit={maxLength}
      />
      {isSavedSeparately && (
        <SaveCancelButton
          onSave={() => {
            onSave(editedValue);
            disableEditMode();
          }}
          onCancel={() => {
            disableEditMode();
            setEditedValue(value);
          }}
          disabled={editedValue === value}
        />
      )}
    </div>
  ) : (
    <div className={styles.valueContainer}>
      <div className={styles.value}>{value}</div>
    </div>
  );
};

export default withErrorBoundary(TextArea);
