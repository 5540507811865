import { useQuery } from "react-query";
import useUser from "./useUser";
import getTemplate from "../api/getTemplate";

import type { Template } from "../types/Template";

type HookResult = {
  templateId: string;
  template: Template;
  page: Page | null;
  pages: string[];
};

type Page = {
  id: string;
  name: string;
  segments: string[];
  withPreview: boolean;
};

const useTemplate = (pageId?: string): HookResult => {
  const { templateId } = useUser();
  const { data: template } = useQuery(
    `template-${templateId}`,
    getTemplate(templateId),
    {
      enabled: !!templateId,
    }
  ) as any;

  const pages = template?.pages.map((page: Page) => page.id);

  if (pageId) {
    const page = template?.pages?.find((page: Page) => page.id === pageId);
    return { template, templateId, page, pages };
  }

  return { template, templateId, page: null, pages };
};

export default useTemplate;
