import { db } from "./firebaseConfig";
import { doc, setDoc } from "firebase/firestore/lite";
// const newUser = {
//   email: "toppantoster@gmial.com",
//   name: "bence.toppanto",
//   site: "kiscica",
//   template: "qdFK1vdlCL3WvpaPNtwR",
//   templates: {
//     qdFK1vdlCL3WvpaPNtwR: {},
//   },
// };

const sampleTemplate = {
  id: "qdFK1vdlCL3WvpaPNtwR",
  businessCategory: "gym",
  name: "Bunker",
  promoPics: [
    "https://firebasestorage.googleapis.com/v0/b/massage-af8e1.appspot.com/o/templates%2FqdFK1vdlCL3WvpaPNtwR%2F369078933_1784126498711477_5263411881108662011_n.jpg?alt=media&token=cdf57bf2-3d7f-43ef-9961-15e28ab6aaa5",
  ],
  data: {
    address: {
      title: "Cím",
      default: "",
      type: "address",
      inputRules: {},
    },
    gallery: {
      title: "Galéria",
      default: [
        "https://picsum.photos/800/600",
        "https://picsum.photos/800/600",
      ],
      type: "carousel",
      inputRules: {
        maxImages: 10,
        aspectRatio: {
          width: 800,
          height: 600,
        },
      },
    },
    aboutImage: {
      title: "Rólunk kép",
      default: "https://picsum.photos/800/600",
      type: "image",
      inputRules: {
        aspectRatio: {
          width: 800,
          height: 600,
        },
      },
    },
    gymName: {
      title: "Terem neve",
      default: "Barbell Bunker",
      type: "textInput",
      inputRules: {
        maxLength: 30,
      },
    },
    mainTitle: {
      title: "Üdvözlő szöveg",
      default: "Üdv a Barbell Bunkerben",
      type: "textInput",
      inputRules: {
        maxLength: 30,
      },
    },
    aboutUsTitle: {
      title: "Rólunk üdvözlő szöveg",
      default: "TÖRTÉNETÜNK és EREDMÉNYEINK",
      type: "textInput",
      inputRules: {
        maxLength: 80,
      },
    },
    aboutUsText: {
      title: "Rólunk szöveg",
      type: "list",
      data: {
        aboutUsParagraph: {
          title: "",
          type: "textArea",
          default: "",
          inputRules: {
            maxLength: 500,
          },
        },
      },
    },
    trainers: {
      title: "Edzők",
      type: "list",
      data: {
        trainerName: {
          title: "Név",
          type: "textInput",
          default: "",
          inputRules: {
            maxLength: 50,
          },
        },
        trainerInstagram: {
          title: "Instagram",
          type: "textInput",
          default: "",
          inputRules: {
            maxLength: 100,
          },
        },
        trainerImage: {
          title: "Kép",
          type: "image",
          default: "https://picsum.photos/600/350",
          inputRules: {
            aspectRatio: {
              height: 600,
              width: 350,
            },
          },
        },
        trainerDescription: {
          title: "Leírás",
          type: "textArea",
          default: "",
          inputRules: {
            maxLength: 500,
          },
        },
      },
    },
    news: {
      title: "Hírek",
      type: "list",
      dataOrder: ["newsTitle", "newsBody", "instagramLink"],
      data: {
        newsTitle: {
          title: "Cím",
          type: "textInput",
          default: "",
          inputRules: {
            maxLength: 50,
          },
        },
        newsBody: {
          title: "Hír szövege",
          type: "textArea",
          default: "",
          inputRules: {
            maxLength: 300,
          },
        },
        instagramLink: {
          title: "Instagram link",
          type: "textInput",
          default: "",
          inputRules: {
            maxLength: 500,
          },
        },
      },
    },
    reviews: {
      title: "Vélemények",
      type: "list",
      dataOrder: ["reviewerName", "review", "reviewImage"],
      data: {
        reviewerName: {
          title: "Név",
          type: "textInput",
          default: "",
          inputRules: {
            maxLength: 30,
          },
        },
        review: {
          title: "Vélemény szövege",
          type: "textArea",
          default: "",
          inputRules: {
            maxLength: 300,
          },
        },
        reviewImage: {
          title: "Kép",
          type: "image",
          inputRules: {
            aspectRatio: {
              height: 50,
              width: 50,
            },
          },
        },
      },
    },
    powerliftingDescription1: {
      title: "Az erőemelésről 1. bekezdés",
      type: "textArea",
      default: "",
      inputRules: {
        maxLength: 500,
      },
    },
    powerliftingDescription2: {
      title: "Az erőemelésről 2. bekezdés",
      type: "textArea",
      default: "",
      inputRules: {
        maxLength: 500,
      },
    },
    trainingSegments: {
      title: "Erőemelés bemutatása",
      type: "list",
      dataOrder: ["title", "firstParagraph", "secondParagraph", "image"],
      data: {
        title: {
          title: "Cím",
          type: "textInput",
          default: "",
          inputRules: {
            maxLength: 50,
          },
        },
        firstParagraph: {
          title: "Első bekezdés",
          type: "textArea",
          default: "",
          inputRules: {
            maxLength: 500,
          },
        },
        secondParagraph: {
          title: "Második bekezdés",
          type: "textArea",
          default: "",
          inputRules: {
            maxLength: 500,
          },
        },
        image: {
          title: "Bemuatató kép",
          type: "image",
          default: "https://picsum.photos/900/600",
          inputRules: {
            aspectRatio: {
              width: 900,
              height: 600,
            },
          },
        },
      },
    },
    siteTitle: {
      title:
        "A weboldal címe (ezt a nevet fogja a böngésző megjeleníteni a weboldal lapján)",
      type: "textInput",
      default: "Barbell Bunker",
      inputRules: {
        maxLength: 30,
      },
    },
    survey: {
      title: "Kédőív",
      type: "form",
      default: "",
      inputRules: {},
    },
    radio: {
      title: "Radio",
      type: "radioButtons",
      default: "",
      inputRules: {
        options: ["Male", "Female", "Helicopter"],
      },
    },
    select: {
      title: "Select",
      type: "select",
      default: "",
      inputRules: {
        options: ["Male", "Female", "Helicopter"],
      },
    },
    checkbox: {
      title: "Checkbox",
      type: "checkbox",
      default: "",
      inputRules: {
        label: "Helicopter vagy?",
      },
    },
    phone: {
      title: "Telefonszám",
      type: "phoneNumber",
      default: "",
      inputRules: {},
    },
  },
  pages: [
    // {
    //   id: "generalInfo",
    //   name: "Általános adatok",
    //   withPreview: false,
    //   segments: ["checkbox", "select", "radio", "survey"],
    // },
    {
      id: "landing",
      name: "Főoldal",
      withPreview: true,
      segments: [
        "news",
        "gymName",
        "mainTitle",
        "aboutUsTitle",
        "aboutUsText",
        "aboutImage",
        "trainers",
        "powerliftingDescription1",
        "powerliftingDescription2",
        "trainingSegments",
        "gallery",
        "reviews",
        "phone",
      ],
    },
  ],
};

const insertTemplate = async () => {
  //await addDoc(collection(db, "users"), newUser);
  // await setDoc(doc(db, "users", "qmhRV7vwdWZXKxYm1iDqPHFtr5w2"), newUser, {
  //   merge: false,
  // });
  await setDoc(doc(db, "templates", "qdFK1vdlCL3WvpaPNtwR"), sampleTemplate);
};

export default insertTemplate;
