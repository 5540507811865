import { Button } from "antd";
import styles from "./style.module.css";
import { CloseOutlined, ToolOutlined } from "@ant-design/icons";
import { useState } from "react";
import insertTemplate from "../../api/insertTemplate";
import withErrorBoundary from "../ErrorBoundary";

const DevToolbar = () => {
  const [isDevToolbarVisible, setIsDevToolbarVisible] = useState(false);

  // To add a new dev button, add a new object to the array below.
  const devButtons = [
    { text: "Update Template", function: insertTemplate },
  ];

  const handleToolButtonClick = () => {
    setIsDevToolbarVisible(true);
  };

  const handleCloseButtonClick = () => {
    setIsDevToolbarVisible(false);
  };

  return (
    <div className={styles.devToolbar}>
      {isDevToolbarVisible ? (
        <Button
          className={styles.actionButton}
          onClick={handleCloseButtonClick}
        >
          <CloseOutlined />
        </Button>
      ) : (
        <Button className={styles.actionButton} onClick={handleToolButtonClick}>
          <ToolOutlined />
        </Button>
      )}
      {isDevToolbarVisible &&
        devButtons.map((button, i) => (
          <Button
            key={`devButton-${i}`}
            className={styles.devButton}
            onClick={() => button.function()}
          >
            {button.text}
          </Button>
        ))}
    </div>
  );
};

export default withErrorBoundary(DevToolbar);
