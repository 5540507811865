import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
  useParams,
  Navigate,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";

import Dashboard from "../../routes/Dashboard";
import Template from "../../routes/Template";
import Settings from "../../routes/Settings";
import WelcomeToTheDashboard from "../../routes/WelcomeToTheDashboard";
import AuthLayout from "../../routes/AuthLayout";
import Landing from "../../routes/Landing";
import ErrorPage from "../ErrorPage";
import { useEffect, useState } from "react";
import { onAuthStateChanged, User } from "firebase/auth";
import { auth } from "../../api/firebaseConfig";
import { AuthContext } from "../../api/authContext";
import { ConfigProvider } from "antd";

import "./index.css";

// import insertTemplate from "../../api/insertTemplate";

const queryClient = new QueryClient();

declare global {
  // eslint-disable-next-line no-var
  var FIREBASE_APPCHECK_DEBUG_TOKEN: boolean | string | undefined;
}

const TemplateWrapper = () => {
  const { pageName } = useParams();

  return <Template key={pageName} />;
};

const App = () => {
  const [user, setUser] = useState<User | undefined | null>(null);

  useEffect(() => {
    onAuthStateChanged(auth, (userObj: User | null) => {
      if (userObj) {
        console.log("User Logged in!");
        setUser(userObj);
      } else {
        setUser(undefined);
        console.log("user is logged out");
      }
    });
  }, []);

  const templateRouter = [
    {
      path: "template",
      children: [
        {
          path: "",
          element: <div>template</div>,
        },
        {
          path: ":pageName",
          element: <TemplateWrapper />,
        },
      ],
    },
  ];

  const router = createBrowserRouter([
    {
      path: "",
      element: <AuthLayout user={user} />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "/",
          element: <Landing user={user}></Landing>,
        },
        {
          path: "dashboard",
          element: <Dashboard />,
          children: [
            {
              path: "",
              element: <WelcomeToTheDashboard />,
            },
            {
              path: "settings",
              element: <Settings />,
            },
            ...templateRouter,
            {
              path: "*",
              element: <Navigate to="/dashboard" replace />,
            },
          ],
        },
        {
          path: "*",
          element: <Navigate to="/" replace />,
        },
      ],
    },
  ]);

  return (
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <AuthContext.Provider value={user}>
          <ConfigProvider
            theme={{
              token: {
                colorBgContainerDisabled: "rgba(255,255,255, 0.8)",
                colorTextDisabled: "grey",
              },
            }}
          >
            {/* <button onClick={insertTemplate}>InsertTemplate</button> */}
            <RouterProvider router={router} />
          </ConfigProvider>
        </AuthContext.Provider>
      </QueryClientProvider>
    </React.StrictMode>
  );
};

export default App;
