import { useState } from "react";
import {
  auth,
  googleAuthProvider,
  facebookAuthProvider,
} from "../../../api/firebaseConfig";
import { signInWithPopup } from "firebase/auth";
import { signInWithEmailAndPassword } from "firebase/auth";
import "./style.css";
import LargeButton from "../LargeButton";
import { parseFirebaseAuthErrorMessage } from "../../../utils/helpers";

function LoginModal({ onClose, switchAuthMode }: any) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [signInPending, setSignInPending] = useState(false);
  const [error, setError] = useState("");

  const handleEmailChange = (event: any) => {
    setEmail(event.target.value);
    setError("");
  };

  const handlePasswordChange = (event: any) => {
    setPassword(event.target.value);
    setError("");
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    setSignInPending(true);
    await signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        onClose();
        setSignInPending(false);
      })
      .catch((error) => {
        setSignInPending(false);
        setError(parseFirebaseAuthErrorMessage(error));
      });
  };

  const signInWithGoogle = (event: any) => {
    event.preventDefault();

    signInWithPopup(auth, googleAuthProvider)
      .then(() => {
        console.log("Logged in");
        onClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const signInWithFacebook = (event: any) => {
    event.preventDefault();

    signInWithPopup(auth, facebookAuthProvider)
      .then(() => {
        console.log("Logged in");
        onClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="login-form">
      <div className="header">
        <span className="title">Bejelentkezés</span>
        <div>
          <div
            className="switch-mode"
            onClick={() => switchAuthMode("register")}
          >
            <span className="switch-mode-text">Még nem regisztráltál? </span>
            Regisztráció
          </div>
        </div>
      </div>
      <div className="input-group user-name">
        <label htmlFor="email" className="label">
          Email cím
        </label>
        <input
          id="email"
          name="email"
          type="email"
          onChange={handleEmailChange}
          required
          className={`input ${error && "error"}`}
        />
        <div className="error-box">{error}</div>
      </div>
      <div className="input-group password">
        <label htmlFor="password" className="label">
          Jelszó
        </label>
        <input
          id="password"
          name="password"
          type="password"
          autoComplete="current-password"
          onChange={handlePasswordChange}
          required
          className={`input ${error && "error"}`}
        />
        <div
          className="switch-mode"
          onClick={() => switchAuthMode("passwordRecovery")}
        >
          Elfelejtett jelszó
        </div>
      </div>
      <LargeButton
        text="Bejelentkezés"
        onClick={handleSubmit}
        disabled={!(email.length && password.length) || signInPending}
        isLoading={signInPending}
      />
      <div className="social-buttons">
        <button className="social-button" onClick={signInWithGoogle}>
          <img
            src={process.env.PUBLIC_URL + "/assets/google.svg"}
            alt="Google"
            className="social-icon"
          />
          Bejelentkezés Google fiókkal
        </button>
        <button className="social-button" onClick={signInWithFacebook}>
          <img
            src={process.env.PUBLIC_URL + "/assets/facebook.svg"}
            alt="Facebook"
            className="social-icon"
          />
          Bejelentkezés Facebook fiókkal
        </button>
      </div>
    </div>
  );
}

export default LoginModal;
