import { useContext } from "react";
import { useQuery } from "react-query";
import getUserDetails from "../api/getUserDetails";
import { AuthContext } from "../api/authContext";

import type { User } from "../types/User";

type HookResult = {
  user: User;
  uid: string;
  templateId: string;
  refetch: () => void;
};

const useUser = (): HookResult => {
  const { uid } = useContext(AuthContext) as any;
  const { data: user, refetch } = useQuery(
    `user-${uid}`,
    getUserDetails(uid)
  ) as any;
  const templateId = user?.template ? user.template : null;

  return { user, uid, templateId, refetch };
};

export default useUser;
