export function isValidEmail(email: string): boolean {
  const emailRegex =
  // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(email);
}

export function parseFirebaseAuthErrorMessage(
  error: any,
  forPasswordRecovery: boolean = false
): string {
  switch (error.code) {
    case "auth/user-not-found":
    case "auth/wrong-password":
      return forPasswordRecovery
        ? "Hibás e-mail cím"
        : "Hibás e-mail cím vagy jelszó.";
    case "auth/invalid-email":
      return "Érvénytelen e-mail cím.";
    case "auth/email-already-in-use":
      return "Az e-mail cím már használatban van.";
    case "auth/weak-password":
      return "A jelszó túl gyenge.";
    default:
      return "Ismeretlen hiba történt.";
  }
}
