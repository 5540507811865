import "./style.css";

type SpinnerProps = {
  size?: number;
  thickness?: number;
  style?: any;
};

const Spinner = ({ size = 50, thickness = 5, style = {} }: SpinnerProps) => (
  <div
    className="loading-spinner"
    style={{ height: size, width: size, borderWidth: thickness, ...style }}
  ></div>
);

export default Spinner;
