import useUser from "./useUser";
import useTemplate from "./useTemplate";

const useMergeUserAndDefaultTemplate = (pageName: string) => {
  const { user, templateId } = useUser();
  const { template } = useTemplate();
  if (user && template) {
    const page = template.pages.find((page) => page.id === pageName);

    const res = page?.segments.map((segment) => {
      const userValue = user?.templates?.[templateId]?.[segment];

      const segmentData = template.data[segment];

      let value = userValue || segmentData.default;

      return {
        value: value,
        id: segment,
        default: segmentData.default,
        title: segmentData.title,
        type: segmentData.type, // !== "list" ? segmentData.type : "textInput",
        inputRules: segmentData.inputRules,
        data: segmentData.data,
        // for list types
        dataOrder: segmentData.dataOrder,
      };
    });
    return res;
  }

  return [];
};

export default useMergeUserAndDefaultTemplate;
