import { v4 as uuid } from "uuid";
import Image from "../Image";
import styles from "./style.module.css";
import "react-slideshow-image/dist/styles.css";
import deleteImageByUrl from "../../../../api/deleteImageByUrl";
import { DeleteOutlined } from "@ant-design/icons";
import { Button } from "antd";
import withErrorBoundary from "../../../../components/ErrorBoundary";

const Carousel = ({
  data,
  editMode,
  disableEditMode,
  onSave,
  inputRules,
  value,
}: any) => {
  const {
    aspectRatio: { width, height },
    maxImages,
  } = inputRules;

  const deleteImage = (url: string) => {
    onSave(value.filter((image: string) => image !== url));
    deleteImageByUrl(url);
  };

  return (
    <div>
      <div style={{ width }}>
        {editMode ? (
          <div className={styles.thumbnailContainer}>
            {value.map((url: string) => {
              return (
                <div
                  key={url}
                  style={{ width: "100px" }}
                  className={styles.thumbnail}
                >
                  <img
                    style={{ userSelect: "none", borderRadius: "5px" }}
                    draggable="false"
                    alt=""
                    src={url}
                    width="100px"
                  ></img>
                  <div
                    className={styles.deleteIcon}
                    onClick={() => {
                      deleteImage(url);
                    }}
                  >
                    <DeleteOutlined />
                  </div>
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
      {editMode ? (
        value.length < maxImages ? (
          <Image
            data={{ ...data, segmentId: `${data.segmentId}-${uuid()}}` }}
            inputRules={{ aspectRatio: { width, height } }}
            refreshPreview={() => {}}
            onSave={() => {}}
            isSavedSeparately={false}
            changeValueInParent={(url: any) => {
              onSave([...value, url]);
            }}
          ></Image>
        ) : (
          <div>Maximum {maxImages} darab képet tölthetsz fel.</div>
        )
      ) : null}
      {editMode ? (
        <>
          <Button className={styles.endEditButton} onClick={disableEditMode}>
            Szerkesztés befejezése
          </Button>
        </>
      ) : null}
    </div>
  );
};

export default withErrorBoundary(Carousel);
