const mergeUserAndDefaultTemplateList = (segments: any, userData: any) => {
  // console.log(userData);

  // map through the array of userData
  const mappedUserData = userData?.map((ud: any) => {
    const dataFields = Object.keys(segments).map((segmentName: string) => {
      const segment = segments[segmentName];
      const userValue = ud[segmentName];

      // const inputRules: {
      //   maxLength?: number;
      //   width?: number;
      //   height?: number;
      // } = {};
      // if (segment.type === "textInput" || segment.type === "textArea") {
      //   inputRules.maxLength = segment.maxLength;
      // }
      // if (segment.type === "image") {
      //   inputRules.width = segment.aspectRatio.width;
      //   inputRules.height = segment.aspectRatio.height;
      // }

      return {
        value: userValue || userValue === "" ? userValue : segment.default,
        id: segmentName,
        default: segment.default,
        title: segment.title,
        type: segment.type,
        inputRules: segment.inputRules,
      };
    });

    return { dataId: ud.dataId, data: dataFields };
  });

  return mappedUserData;
};

export default mergeUserAndDefaultTemplateList;
