import { useState, useRef, useEffect } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { UploadOutlined } from "@ant-design/icons";
import uploadImage from "../../../../api/uploadImage";
import { canvasPreview } from "./canvasPreview";
import styles from "./style.module.css";
import Spinner from "../../../../components/LoadingSpinner";
import SaveCancelButton from "../../../../components/SaveCancelButtons";
import EditButton from "../../../../components/EditButton";
import withErrorBoundary from "../../../../components/ErrorBoundary"; 
import resizeImage from "../../../../utils/imageResizer"; 

type ImageProps = {
  data: string;
  inputRules: {
    aspectRatio: {
      width: number;
      height: number;
    };
  };
  value?: string;
  refreshPreview: () => void;
  onSave: (value: string) => void;
  isSavedSeparately: boolean;
  changeValueInParent: (value: string) => void;
};

const Image = ({
  data,
  inputRules,
  value,
  refreshPreview,
  isSavedSeparately,
  changeValueInParent,
  onSave,
}: ImageProps) => {
  const [image, setImage] = useState() as any;
  const [crop, setCrop] = useState() as any;
  const [completedCrop, setCompletedCrop] = useState() as any;
  const [imageSeed, setImageSeed] = useState(0);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isUploading, setIsUploading] = useState(false);
  const {
    aspectRatio: { width, height },
  } = inputRules;
 
  useEffect(() => {
    if (
      completedCrop?.width &&
      completedCrop?.height &&
      imgRef.current &&
      previewCanvasRef.current
    ) {
      // We use canvasPreview as it's much faster than imgPreview.
      canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
    }
  }, [completedCrop]);

  const onUploadCropClick = async () => {
    setIsUploading(true);

    if (!previewCanvasRef.current) {
      throw new Error("Crop canvas does not exist");
    }

    const resized = await resizeImage(previewCanvasRef.current, width, height);
    const url = await uploadImage(resized, data)();    

    if (isSavedSeparately) {
      onSave(url);

      setImage(resized);
      refreshPreview();
    } else {
      changeValueInParent(url);
    }
    cancelUpload();
    setImageSeed(imageSeed + 1);
    setIsUploading(false);
  };

  const cancelUpload = () => {
    setImage();
    setCompletedCrop();
    setCrop();
  };

  const calcMaxCrop = () => {
    if (!imgRef.current) {
      return null;
    }
    const imgWidth = imgRef.current.width;
    const imgHeight = Math.min(imgRef.current.height, 300);
    const aspect = width / height;
    let crop = {};
    if (imgWidth / imgHeight > aspect) {
      crop = {
        unit: "px",
        width: imgHeight * aspect,
        height: imgHeight,
        x: (imgWidth - imgHeight * aspect) / 2,
        y: 0,
      };
    } else {
      crop = {
        unit: "px",
        width: imgWidth,
        height: imgWidth / aspect,
        x: 0,
        y: (imgHeight - imgWidth / aspect) / 2,
      };
    }
    setCrop(crop);
    return crop;
  };

  return (
    <>
      {image && (
        <div className={styles.imageCropContainer}>
          <div className={styles.imageCrop}>
            <div className={styles.imageCropTitle}>Kép körbevágása</div>
            <ReactCrop
              crop={crop}
              onChange={(c) => {
                setCrop(c);
                setCompletedCrop(c);
              }}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={width / height}
              ruleOfThirds
              style={{ backgroundColor: "black" }}
              keepSelection={true}
            >
              <img
                onLoad={() => calcMaxCrop()}
                alt=""
                ref={imgRef}
                src={image}
                className={styles.reactCropImage}
              />
            </ReactCrop>
          </div>
          <div className={styles.canvasContainer}>
            <div className={styles.imageCropTitle}>Előnézet</div>
            <canvas
              className={`${styles.previewCanvas} ${
                !isSavedSeparately && styles.previewCanvasListMode
              }`}
              ref={previewCanvasRef}
            />
          </div>
        </div>
      )}
      <img
        alt=""
        src={`${value}?${imageSeed}`}
        style={{ display: image ? "none" : "block" }}
        className={`${styles.loadedImage} ${
          value && styles.loadedImageMinHeight
        }`}
      />
      <input
        className={styles.hidden}
        ref={inputRef}
        type="file"
        accept="image/*"
        onClick={(e: any) => {
          e.target.value = null;
        }}
        onChange={async (e: any) => {
          setImage(URL.createObjectURL(e.target.files[0]) as any);
          calcMaxCrop();
        }}
      />
      {image && (
        <div className={styles.buttonsDiv}>
          {isUploading ? (
            <Spinner size={26} thickness={3} />
          ) : (
            <SaveCancelButton
              onSave={onUploadCropClick}
              onCancel={cancelUpload}
              saveText="Kép mentése"
              cancelText="Mégse"
            />
          )}
        </div>
      )}
      {!image && (
        <EditButton
          onClick={() => inputRef.current?.click()}
          text="Új kép választása"
          className={styles.choseImageBtn}
          icon={<UploadOutlined />}
        />
      )}
    </>
  );
};

export default withErrorBoundary(Image);
