import { storage } from "./firebaseConfig";
import { getDownloadURL, ref, uploadString } from "firebase/storage";

const uploadImage = (
  file: any,
  {
    userId = "userId",
    templateId = "templateId",
    pageId = "pageId",
    segmentId = "segmentId",
  }: any,
) =>
async () => {
  const storageRef = ref(
    storage,
    `/images/${userId}/${templateId}/${pageId}/${segmentId}`,
  );
  await uploadString(storageRef, file, "data_url").then(() => {
    console.log("Uploaded a data_url string!");
  });
  const downloadURL = await getDownloadURL(
    ref(storage, `/images/${userId}/${templateId}/${pageId}/${segmentId}`),
  );
  return downloadURL;
};

export default uploadImage;
