import React, { ReactNode } from "react";
import styles from "./style.module.css";
import { Tooltip } from "react-tooltip";

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: string;
  errorStack: string;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: "", errorStack: "" };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    try {
      this.setState({
        errorStack: errorInfo?.componentStack || "",
        error: error || "",
      });
    } catch (e) {
      console.log(e);
    }
  }

  copyErrorMessageToClipboard = () => {
    try {
      const errorMessage = `${this.state.error}\n${this.state.errorStack}`;
      navigator.clipboard.writeText(errorMessage);
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    if (this.state.hasError) {
      return (
        <div className={styles.errorDiv}>
          Valami hiba történt. Kérjük, frissítsd az oldalt, és ha a hiba
          továbbra is fennáll, lépj kapcsolatba velünk az{" "}
          <b>support@webgen.hu</b> e-mail címen.
          <div className={styles.copyButtonDiv}>
            <button
              data-tooltip-id="tooltip-click"
              data-tooltip-content="Vágólapra másolva!"
              className={styles.copyButton}
              onClick={this.copyErrorMessageToClipboard}
            >
              Hibaüzenet másolása a vágólapra
            </button>
            <Tooltip
              id="tooltip-click"
              openOnClick={true}
              data-tooltip-variant="success"
            />
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

const withErrorBoundary = (Component: any) => {
  return (props: any) => {
    return (
      <ErrorBoundary>
        <Component {...props} />
      </ErrorBoundary>
    );
  };
};

export default withErrorBoundary;
