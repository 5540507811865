import { Button } from "antd";
import styles from "./style.module.css";

type Props = {
  onClick: () => void;
  className?: string;
  text?: string;
  disabled: boolean;
};

const SaveButton = ({
  onClick,
  className,
  text = "Mentés",
  disabled = false,
}: Props) => {
  return (
    <Button
      className={`${styles.saveButton} ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};

export default SaveButton;
