import "./style.css";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { Button } from "antd";
import withErrorBoundary from "../ErrorBoundary";

type Props = {
  id: string;
  name: string;
  pictures: string[];
  onSelect: Function;
  isSelected: boolean;
  exampleURL?: string;
};

const TemplateCard = ({
  id,
  name,
  pictures,
  onSelect,
  isSelected,
  exampleURL,
}: Props) => {
  return (
    <div
      className={`templateCard ${isSelected && "template-card-selected"}`}
      key={id}
    >
      <div className="title">{name}</div>
      <a href={`https://${exampleURL}`} target="_blank" rel="noreferrer">
        <img
          src={process.env.PUBLIC_URL + "/assets/external-link.svg"}
          alt="open-external-link"
          className="open-external-icon"
        />
      </a>
      <div className="image-slideshow">
        <Slide autoplay={false} transitionDuration={500}>
          {pictures.map((p, i) => (
            <div key={i} className="each-slide-effect">
              <div style={{ backgroundImage: `url(${p})` }}></div>
            </div>
          ))}
        </Slide>
      </div>
      {isSelected ? (
        <div className="selected-text">Kiválasztva</div>
      ) : (
        <Button onClick={() => onSelect()} className="select-template-button">
          Sablon kiválasztása
        </Button>
      )}
    </div>
  );
};

export default withErrorBoundary(TemplateCard);
