import { Radio } from "antd";
import withErrorBoundary from "../../../../components/ErrorBoundary";

type RadioButtonsProps = {
  data: string;
  inputRules: {
    options: string[];
  };
  value?: string;
  refreshPreview: () => void;
  onSave: (value: string) => void;
  isSavedSeparately: boolean;
  changeValueInParent: (value: string) => void;
};

const RadioButtons = ({ inputRules, value, onSave }: RadioButtonsProps) => {
  return (
    <Radio.Group onChange={(e) => onSave(e.target.value)} value={value}>
      {inputRules.options.map((option) => (
        <Radio key={option} value={option}>
          {option}
        </Radio>
      ))}
    </Radio.Group>
  );
};

export default withErrorBoundary(RadioButtons);
