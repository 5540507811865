import WebsiteName from "../../components/WebsiteName";
import TemplatePicker from "../../components/TemplatePicker";

import "./style.css";

const Settings = () => {
  return (
    <div>
      <h1>Beállítások</h1>
      <hr />
      <WebsiteName></WebsiteName>
      <hr />
      <TemplatePicker></TemplatePicker>
    </div>
  );
};

export default Settings;
