import { db } from "./firebaseConfig";
import { collection, getDocs, query, where } from "firebase/firestore/lite";

const getUserDetails = (id: string) => async () => {
  const collectionInstance = collection(db, "users");
  const q = query(collectionInstance, where("__name__", "==", id));
  const snapshot = await getDocs(q);
  const result = snapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  return result?.[0];
};

export default getUserDetails;
