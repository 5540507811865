import { useState } from "react";
import {
  auth,
  sendPasswordResetEmail,
} from "../../../api/firebaseConfig";
import "./style.css";
import { isValidEmail } from "../../../utils/helpers";
import LargeButton from "../LargeButton";
import { parseFirebaseAuthErrorMessage } from "../../../utils/helpers";

function PasswordRecoveryModal({ switchAuthMode }: any) {
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isRecoveryEmailSent, setIsRecoveryEmailSent] = useState(false);
  const [isRecoveryEmailSending, setIsRecoveryEmailSending] = useState(false);
  const [error, setError] = useState("");

  const handleEmailChange = (event: any) => {
    const value = event.target.value;
    setEmail(value);
    setIsEmailValid(isValidEmail(value));
    setError("");
  };

  const handlePasswordRecoveryRequest = async (event: any) => {
    event.preventDefault();

    setIsRecoveryEmailSending(true);
    await sendPasswordResetEmail(auth, email)
      .then(() => {
        setIsRecoveryEmailSent(true);
        setIsRecoveryEmailSending(false);
      })
      .catch((error) => {
        setError(parseFirebaseAuthErrorMessage(error, true));
        setIsRecoveryEmailSending(false);
      });
  };

  return (
    <div className="form">
      <div className="header">
        <span className="title">Elfelejtett jelszó</span>
        <div>
          <div className="switch-mode" onClick={() => switchAuthMode("login")}>
            <span className="switch-mode-text">Vissza a </span>
            Bejelentkezéshez
          </div>
        </div>
      </div>
      <div className="input-group">
        <label htmlFor="email" className="label">
          Email cím
        </label>
        <input
          id="email"
          name="email"
          type="email"
          onChange={handleEmailChange}
          required
          className={`input ${error && "error"}`}
        />
        <div className="error-box">{error}</div>
      </div>

      {isRecoveryEmailSent ? (
        <>
          Jelszó visszaállító email elküldve. Kérjük, lépj be a postafiókodba és
          kövesd az emailben leírt lépéseket.
        </>
      ) : (
        <LargeButton
          text="Jelszó visszaállítás kérése"
          onClick={handlePasswordRecoveryRequest}
          disabled={!isEmailValid || isRecoveryEmailSending}
          isLoading={isRecoveryEmailSending}
        />
      )}
    </div>
  );
}

export default PasswordRecoveryModal;
