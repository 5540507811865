import { useState } from "react";
import styles from "./style.module.css";
import EditButton from "../../../components/EditButton";
import withErrorBoundary from "../../../components/ErrorBoundary";

const simpleEditComponents = ["image", "radioButtons", "select", "checkbox"];

const TemplateWrapper = ({
  name,
  onChange,
  onSave,
  value,
  type,
  data,
  inputRules,
  dataSegments,
  segments,
  refreshPreview,
  // whether the input field is saved alone or part of a larger input (e.g. List)
  isSavedSeparately = true,
  changeValueInParent,
  dataOrder,
}: any) => {
  const Segment = (segments as any)[type];

  const [editMode, setEditMode] = useState(!isSavedSeparately);

  return (
    <div
      className={`${styles.templateSegment} ${
        !isSavedSeparately && styles.templateSegmentInList
      }`}
    >
      <div className={styles.templateSegmentContent}>
        <div className={styles.templateSegmentComponent}>
          <div
            className={`${styles.templateSegmentTitle} ${
              !isSavedSeparately && styles.templateSegmentTitleInList
            }`}
          >
            {name}
            {!editMode && !simpleEditComponents.includes(type) ? (
              <>
                <EditButton
                  className={styles.editButton}
                  onClick={() => setEditMode(!editMode)}
                />
              </>
            ) : null}
          </div>
            <Segment
              onSave={onSave}
              onChange={onChange}
              value={value}
              data={data}
              dataSegments={dataSegments}
              refreshPreview={refreshPreview}
              editMode={editMode}
              disableEditMode={() => setEditMode(false)}
              isSavedSeparately={isSavedSeparately}
              changeValueInParent={changeValueInParent}
              inputRules={inputRules}
              dataOrder={dataOrder}
            ></Segment>
        </div>
      </div>
    </div>
  );
};

export default withErrorBoundary(TemplateWrapper);
