import { Button } from "antd";
import styles from "./style.module.css";

type Props = {
  onClick: () => void;
  className?: string;
  text?: string;
};

const CancelButton = ({ text = "Mégse", onClick, className }: Props) => {
  return (
    <Button className={`${styles.cancelButton} ${className}`} onClick={onClick}>
      {text}
    </Button>
  );
};

export default CancelButton;
