import TextArea from "./TextArea";
import PhoneNumber from "./PhoneNumber";
import TextInput from "./TextInput";
import Image from "./Image";
import List from "./List";
import OpeningHours from "./OpeningHours";
import Address from "./Address";
import Carousel from "./Carousel";
import Form from "./Form";
import RadioButtons from "./RadioButtons";
import Select from "./Select";
import Checkbox from "./Checkbox";

const segments = {
  textArea: TextArea,
  phoneNumber: PhoneNumber,
  textInput: TextInput,
  image: Image,
  list: List,
  openingHours: OpeningHours,
  address: Address,
  carousel: Carousel,
  form: Form,
  radioButtons: RadioButtons,
  select: Select,
  checkbox: Checkbox,
};

export default segments;
