import { useContext, useState, useEffect } from "react";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { getPreviewURL } from "../../utils/envConfig";

import { AuthContext } from "../../api/authContext";
import updateUserTemplate from "../../api/updateUserTemplate";

import TemplateWrapper from "./TemplateWrapper";

import useMergeUserAndDefaultTemplate from "../../hooks/useMergeUserAndDefaultTemplate";
import useMergeUserAndDefaultTemplateForPreview from "../../hooks/useMergeUserAndDefaultTemplateForPreview";
import useUser from "../../hooks/useUser";
import useTemplate from "../../hooks/useTemplate";
import "./style.css";
import { Segment } from "../../types/Template";
import segments from "./segments";

import { CloseOutlined } from "@ant-design/icons";
import { Button } from "antd";
import withErrorBoundary from "../../components/ErrorBoundary";

const Template = () => {
  const { pageName } = useParams();

  const { templateId, refetch } = useUser();
  const { page, pages } = useTemplate(pageName);
  const valueToProcess = useMergeUserAndDefaultTemplate(pageName!);

  const valuesForPreview = useMergeUserAndDefaultTemplateForPreview();
  const [loaded, setLoaded] = useState(false);
  const [eventListener, setEventListener] = useState() as any;
  const [fullscreenPreview, setFullscreenPreview] = useState(false) as any;
  const [mobilePreview, setMobilePreview] = useState(false) as any;
  const [iframeRef, setIframeRef] = useState(null as HTMLIFrameElement | null);

  const withPreview = page?.withPreview;

  const userDataMutation = useMutation(
    ({
      userId,
      templateId,
      data,
    }: {
      userId: string;
      templateId: string;
      data: any;
    }) => {
      return updateUserTemplate(userId, templateId, data);
    },
    {
      onSuccess: () => {
        refetch();
        iframeRef?.focus();
      },
    }
  );
  const { uid } = useContext(AuthContext) as any;

  const previewURL = getPreviewURL(templateId);

  useEffect(() => {
    window.removeEventListener("message", eventListener);
    setEventListener(
      window.addEventListener("message", (event) => {
        if (event.data === "sendData") {
          iframeRef?.contentWindow?.postMessage(
            {
              name: "data",
              data: { segments: valuesForPreview, pages, templateId },
            },
            previewURL
          );
        } else if (event.data === "exitFullscreen" && fullscreenPreview) {
          setFullscreenPreview(false);
          iframeRef?.contentWindow?.postMessage(
            { name: "navigate", data: `/${pageName}` },
            previewURL
          );
        }
      })
    );
    if (loaded) {
      iframeRef?.contentWindow?.postMessage(
        {
          name: "data",
          data: { segments: valuesForPreview, pages, templateId },
        },
        previewURL
      );
    }
  }, [
    valuesForPreview,
    iframeRef,
    loaded,
    eventListener,
    pageName,
    fullscreenPreview,
    previewURL,
    pages,
    templateId,
  ]);

  useEffect(() => {
    if (loaded) {
      document.addEventListener("keydown", (event) => {
        if (event.key === "Escape") {
          setFullscreenPreview(false);
          iframeRef?.contentWindow?.postMessage(
            { name: "navigate", data: `/${pageName}` },
            previewURL
          );
        }
      });
    }
  }, [loaded, iframeRef, pageName, previewURL]);

  const onDataUpdate = (segmentId: string, data: any) => {
    userDataMutation.mutate({
      userId: uid,
      templateId,
      data: { [segmentId]: data },
    });
  };

  const refreshPreview = () => {
    iframeRef?.contentWindow?.postMessage(
      {
        name: "refresh",
      },
      previewURL
    );
  };

  return (
    <div className="text" key={pageName}>
      <div className="template">
        <div className="template-header">{page?.name}</div>
      </div>
      {valueToProcess && withPreview && (
        <div
          className={`${
            !fullscreenPreview
              ? mobilePreview
                ? "wrap-mobile"
                : "wrap"
              : mobilePreview
              ? "fullscreen-wrap-mobile"
              : "fullscreen-wrap"
          }
      `}
        >
          {fullscreenPreview ? (
            <Button
              onClick={() => setFullscreenPreview(false)}
              className="exitFullScreenBtn"
              type="primary"
              size="middle"
              icon={<CloseOutlined className="closeButtonIcon" />}
            />
          ) : (
            <>
              <Button
                className="fullScreenBtn"
                onClick={() => setFullscreenPreview(true)}
              >
                Teljes képernyős nézet
              </Button>
              <Button
                className="mobileViewBtn"
                onClick={() => setMobilePreview(!mobilePreview)}
              >
                {mobilePreview ? "Asztali nézet" : "Mobil nézet"}
              </Button>
            </>
          )}
          <iframe
            ref={(e) => setIframeRef(e)}
            className={`${fullscreenPreview ? "fullscreen" : "frame"} ${
              mobilePreview ? "mobileView" : ""
            }`}
            width="100%"
            src={`${previewURL}/${pageName}`}
            title="preview page"
            onLoad={() => setLoaded(true)}
          ></iframe>
        </div>
      )}
      <hr />
      <div className="template-page">
        {valueToProcess?.map(
          (
            segment: {
              default: string;
              id: string;
              title: string;
              type: string;
              value: string;
              inputRules: {
                maxLength?: number;
                width?: number;
                height?: number;
              };
              // for list type
              data: Segment[] | undefined;
              dataOrder: string[] | undefined;
            },
            index: number
          ) => {
            return (
              <div key={`Page-${index}`} style={{ marginBottom: "20px" }}>
                <TemplateWrapper
                  type={segment.type}
                  name={segment.title}
                  onSave={(value: any) => onDataUpdate(segment.id, value)}
                  defaultValue={segment.value}
                  value={segment.value}
                  data={{
                    userId: uid,
                    templateId,
                    pageId: pageName,
                    segmentId: segment.id,
                  }}
                  inputRules={segment.inputRules}
                  segments={segments}
                  refreshPreview={refreshPreview}
                  // for list type
                  dataSegments={segment.data}
                  dataOrder={segment.dataOrder}
                ></TemplateWrapper>
              </div>
            );
          }
        )}
      </div>
    </div>
  );
};

export default withErrorBoundary(Template);
