import SaveButton from "./SaveButton";
import CancelButton from "./CancelButton";

import styles from "./style.module.css";
import withErrorBoundary from "../ErrorBoundary";

type Props = {
  onSave: () => void;
  onCancel: () => void;
  saveText?: string;
  cancelText?: string;
  disabled?: boolean;
};

const SaveCancelButton = ({
  onSave,
  onCancel,
  saveText = "Mentés",
  cancelText = "Mégse",
  disabled = false,
}: Props) => {
  return (
    <div className={styles.buttonsWrapper}>
      <SaveButton onClick={onSave} text={saveText} disabled={disabled} />
      <CancelButton
        onClick={onCancel}
        text={cancelText}
        className={styles.cancelButton}
      />
    </div>
  );
};

export default withErrorBoundary(SaveCancelButton);
