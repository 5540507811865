import { Outlet, useLocation, Navigate } from "react-router-dom";
import { signOut, User } from "firebase/auth";
import { Button } from "antd";
import Spinner from "../../components/LoadingSpinner";
import DevToolbar from "../../components/DevToolbar";
import { shouldDevbarBeShown } from "../../utils/envConfig";

import { auth } from "../../api/firebaseConfig";
import withErrorBoundary from "../../components/ErrorBoundary";

import "./style.css";

const AuthLayout = ({ user }: { user: User | undefined | null }) => {
  const location = useLocation();

  const logOut = () => {
    signOut(auth).catch((error) => {
      console.log(error);
    });
  };

  if (location.pathname !== "/" && user === undefined) {
    return <Navigate to={"/"} replace />;
  }
  if (user === null) {
    // TODO show loading screen
    return (
      <div className="loading-animation-container">
        <Spinner size={30} />
      </div>
    );
  }

  return (
    <>
      <div className="header">
        {shouldDevbarBeShown && <DevToolbar />}
        {user && (
          <Button
            type="default"
            onClick={logOut}
            size="large"
            className="logout-button"
          >
            Kijelentkezés
          </Button>
        )}
      </div>
      <Outlet />
    </>
  );
};

export default withErrorBoundary(AuthLayout);
