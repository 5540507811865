import { db } from "./firebaseConfig";
import { setDoc, doc } from "firebase/firestore/lite";

const updateUserTemplate = (userId: string, templateId: string, data: any) => {
  return setDoc(
    doc(db, "users", userId),
    {
      templates: {
        [templateId]: data,
      },
    },
    { merge: true }
  );
};

export default updateUserTemplate;
