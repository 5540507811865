import { useState } from "react";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import SaveCancelButton from "../../../../components/SaveCancelButtons";
import withErrorBoundary from "../../../../components/ErrorBoundary";

const libraries = ["places"] as any;

const Address = ({
  editMode,
  changeValueInParent,
  disableEditMode,
  onSave,
  value,
}: any) => {
  const [autocomplete, setAutocomplete] = useState(null) as any;
  const [address, setAddress] = useState(value) as any;

  const onLoad = (ac: any) => {
    setAutocomplete(ac);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      setAddress({
        address: autocomplete.getPlace().formatted_address,
        coordinates: autocomplete.getPlace().geometry.location.toJSON(),
      });
    }
  };

  const renderAutocomplete = () =>
    editMode ? (
      <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
        <input
          type="text"
          placeholder="Keresés"
          style={{
            boxSizing: `border-box`,
            border: `1px solid transparent`,
            width: `240px`,
            height: `32px`,
            padding: `0 12px`,
            borderRadius: `3px`,
            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
            fontSize: `14px`,
            outline: `none`,
            textOverflow: `ellipses`,
          }}
        />
      </Autocomplete>
    ) : null;

  return (
    <div>
      Kiválaszott cím: {address.address}
      {editMode && (
        <div>
          <SaveCancelButton
            onSave={() => {
              onSave(address);
              disableEditMode();
            }}
            onCancel={() => {
              setAddress(value);
              disableEditMode();
            }}
          />
        </div>
      )}
      <div className="map-container-outer">
        <div className="map-container">
          {window.google === undefined ? (
            <LoadScript
              libraries={libraries}
              googleMapsApiKey="AIzaSyBZbRCIV1rvOyO0YJLuaa-NTsMIX14JyWk"
            >
              {renderAutocomplete()}
            </LoadScript>
          ) : (
            renderAutocomplete()
          )}
        </div>
      </div>
    </div>
  );
};

export default withErrorBoundary(Address);
