import { useState } from "react";
import { Input, Button, TimePicker, Card } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import styles from "./style.module.css";
import dayjs from "dayjs";
import SaveCancelButton from "../../../../components/SaveCancelButtons";
import withErrorBoundary from "../../../../components/ErrorBoundary";

type ValueType = {
  openingHours: {
    monday: { close: string; open: string }[];
    tuesday: { close: string; open: string }[];
    wednesday: { close: string; open: string }[];
    thursday: { close: string; open: string }[];
    friday: { close: string; open: string }[];
    saturday: { close: string; open: string }[];
    sunday: { close: string; open: string }[];
  };
  notes: string;
};

type OpeningHoursProps = {
  onChange: (value: string) => void;
  value: ValueType;
  onSave: (value: unknown) => void;
  editMode: boolean;
  disableEditMode: () => void;
};

const defaultValue: ValueType = {
  openingHours: {
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: [],
  },
  notes: "",
};

const days = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
] as const;

const dayNameMap: Record<(typeof days)[number], string> = {
  monday: "Hétfő",
  tuesday: "Kedd",
  wednesday: "Szerda",
  thursday: "Csütörtök",
  friday: "Péntek",
  saturday: "Szombat",
  sunday: "Vasárnap",
};

const OpeningHours = ({
  value,
  onSave,
  editMode,
  disableEditMode,
}: OpeningHoursProps) => {
  const [editedValue, setEditedValue] = useState(value || defaultValue);
  return (
    <div className={styles.container}>
      {!editMode && (
        <div>
          {days.map((day, index) => (
            <div key={index}>
              <div
                className={`${styles.dayContent} ${styles.dayContentDisplay}`}
              >
                <div className={styles.dayName}>{dayNameMap[day]}</div>
                <div className={styles.openingTimes}>
                  {editedValue.openingHours[day].length
                    ? editedValue.openingHours[day].map(
                        (item, index: number) => (
                          <div key={index}>
                            {item.open} - {item.close}
                          </div>
                        )
                      )
                    : "Zárva"}
                </div>
              </div>
            </div>
          ))}
          <p className={styles.notesTitle}>
            Megjegyzések, ünnepnapok, egyéb információk:
          </p>
          <div className={styles.notesContent}>{editedValue.notes}</div>
        </div>
      )}
      {editMode && (
        <>
          <div className={styles.cardContainer}>
            {days.map((day, index) => (
              <Card className={styles.card} key={index}>
                <div className={styles.dayContent}>
                  <div className={styles.dayName}>{dayNameMap[day]}</div>
                  <div className={styles.openingTimes}>
                    {editedValue.openingHours[day].map((item, index) => (
                      <div key={index} className={styles.openingHoursRow}>
                        <TimePicker.RangePicker
                          className={styles.openingTime}
                          placeholder={["Nyitás", "Zárás"]}
                          format="HH:mm"
                          onChange={(_, timeString) => {
                            setEditedValue({
                              ...editedValue,
                              openingHours: {
                                ...editedValue.openingHours,
                                [day]: editedValue.openingHours[day].map(
                                  (item, i) =>
                                    i === index
                                      ? {
                                          open: timeString[0],
                                          close: timeString[1],
                                        }
                                      : item
                                ),
                              },
                            });
                          }}
                          value={
                            item
                              ? [
                                  dayjs(item.open, "HH:mm"),
                                  dayjs(item.close, "HH:mm"),
                                ]
                              : undefined
                          }
                        />
                        <Button
                          className={styles.removeCardButton}
                          icon={
                            <DeleteOutlined
                              className={styles.deleteButtonIcon}
                            />
                          }
                          type="primary"
                          size="middle"
                          danger
                          onClick={() =>
                            setEditedValue({
                              ...editedValue,
                              openingHours: {
                                ...editedValue.openingHours,
                                [day]: editedValue.openingHours[day].filter(
                                  (_, i: number) => i !== index
                                ),
                              },
                            })
                          }
                        />
                      </div>
                    ))}

                    <Button
                      className={styles.addButton}
                      icon={<PlusOutlined className={styles.addButtonIcon} />}
                      type="primary"
                      shape="circle"
                      onClick={() =>
                        setEditedValue({
                          ...editedValue,
                          openingHours: {
                            ...editedValue.openingHours,
                            [day]: [...editedValue.openingHours[day], null],
                          },
                        })
                      }
                    />
                  </div>
                </div>
              </Card>
            ))}
          </div>
          Megjegyzések, ünnepnapok, egyéb információk
          <Input.TextArea
            size="large"
            value={editedValue.notes}
            rows={4}
            placeholder="Itt adhatod meg a szöveget"
            onChange={(e) =>
              setEditedValue({ ...editedValue, notes: e.target.value })
            }
            className={styles.noteOtherInfos}
          />
          <SaveCancelButton
            onSave={() => {
              onSave(editedValue);
              disableEditMode();
            }}
            onCancel={() => {
              disableEditMode();
              setEditedValue(value);
            }}
          />
        </>
      )}
    </div>
  );
};

export default withErrorBoundary(OpeningHours);
