import { useState } from "react";
import LoginModal from "./LoginModal";
import RegisterModal from "./RegisterModal";
import PasswordRecoveryModal from "./PasswordRecoveryModal";
import withErrorBoundary from "../ErrorBoundary";
import "./style.css";

function AuthModal({ isOpen, onClose }: any) {
  const [authMode, setAuthMode] = useState("login");

  const switchAuthMode = (
    authMode: "login" | "register" | "passwordRecovery"
  ) => {
    setAuthMode(authMode);
  };

  const closeModal = () => {
    onClose();
    setAuthMode("login");
  };

  if (!isOpen) {
    return null;
  }

  const renderAuthModal = () => {
    switch (authMode) {
      case "login":
        return <LoginModal switchAuthMode={switchAuthMode} onClose={onClose} />;
      case "register":
        return (
          <RegisterModal switchAuthMode={switchAuthMode} onClose={onClose} />
        );
      case "passwordRecovery":
        return (
          <PasswordRecoveryModal
            switchAuthMode={switchAuthMode}
            onClose={onClose}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="auth-modal">
      <div className="auth-modal__container">
        <div className="auth-modal__overlay" onClick={closeModal}></div>
        {renderAuthModal()}
      </div>
    </div>
  );
}

export default withErrorBoundary(AuthModal);
