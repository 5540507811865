import { useState } from "react";
import { useQuery } from "react-query";
import { Button, Input } from "antd";
import { CloseCircleFilled, CheckCircleFilled } from "@ant-design/icons";
import { z } from "zod";
import { httpsCallable } from "firebase/functions";

import { appCheck, functions } from "../../api/firebaseConfig";
import useUser from "../../hooks/useUser";
import Spinner from "../LoadingSpinner";
import { API_ENDPOINT } from "../../utils/constants";

import styles from "./style.module.css";
import { getToken } from "firebase/app-check";
import withErrorBoundary from "../ErrorBoundary";
import { isAllowedSiteName, isProd } from "../../utils/envConfig";

const updateUser = httpsCallable(functions, "updateUser");

type NameStatuses = "unknown" | "available" | "taken";

const NameStatus: Record<Capitalize<NameStatuses>, NameStatuses> = {
  Unknown: "unknown",
  Available: "available",
  Taken: "taken",
};

const functionUrl = `https://europe-west1-massage-af8e1.cloudfunctions.net/isSiteNameAvailable`;

async function deploySite(uid: string) {
  const appCheckTokenResponse = await getToken(appCheck, false);
  const res = await fetch(`${API_ENDPOINT}/deploySite`, {
    method: "POST",
    headers: {
      "X-MASSAGE-TOKEN": appCheckTokenResponse.token,
      "Content-Type": "application/json",
      "Is-Dev": (!isProd).toString(),
    },
    mode: "cors",
    body: JSON.stringify({
      userId: uid,
    }),
  });

  return res.json();
}

async function pollForDeployment(uid: string) {
  const appCheckTokenResponse = await getToken(appCheck, false);
  const res = await fetch(
    `${API_ENDPOINT}/deployment/${uid}`,
    {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "X-MASSAGE-TOKEN": appCheckTokenResponse.token,
        "Is-Dev": (!isProd).toString(),
      },
      mode: "cors",
    }
  );

  return res.json();
}

function debounce<T extends (...args: any[]) => any>(func: T, wait: number) {
  let timeoutId: ReturnType<typeof setTimeout> | null = null;

  return (...args: Parameters<T>) => {
    const later = () => {
      timeoutId = null;
      return func(...args);
    };

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(later, wait);
  };
}

const resSchema = z.object({ available: z.boolean() });

const isSiteNameAvailable = debounce(
  async (name: string, cb: (data: z.infer<typeof resSchema>) => any) => {
    console.log(`Attempting to fetch ${name}`);
    const res = await fetch(`${functionUrl}?site=${name}`);
    const resBody = await res.json();

    try {
      cb(resSchema.parse(resBody));
    } catch (err) {
      console.error(err);
    }
  },
  500
);

const isSiteNameAllowed = async (
  name: string,
  cb: (data: z.infer<typeof resSchema>) => any
) => {
  if (isAllowedSiteName(name)) {
    await isSiteNameAvailable(name, cb);
  } else {
    cb({ available: false });
  }
};

const WebsiteName = () => {
  const { user, refetch, uid, templateId } = useUser();
  const [name, setName] = useState("");
  const [nameStatus, setNameStatus] = useState<NameStatuses>(
    NameStatus.Unknown
  );
  const [settingName, setSettingName] = useState(false);
  const [shouldPoll, setShouldPoll] = useState(false);
  const [isDeploying, setIsDeploying] = useState(false);
  useQuery(
    "deployment",
    async () => {
      try {
        const pollResult = await pollForDeployment(uid);
        // This means that we have already a deployment
        if (pollResult.code === 1) {
          setIsDeploying(true);
        } else {
          setShouldPoll(false);
          setIsDeploying(false);
        }
      } catch (e) {
        console.error(e);
        setIsDeploying(false);
        setShouldPoll(false);
      }
    },
    {
      enabled: shouldPoll,
      refetchInterval: 2000,
    }
  );

  const onNameChange = (value: string) => {
    const validInput = /^[a-z0-9-]{0,63}$/.test(value);

    if (validInput) {
      setName(() => {
        isSiteNameAllowed(value, ({ available }) => {
          setNameStatus(available ? NameStatus.Available : NameStatus.Taken);
        });

        return value;
      });

      setNameStatus(NameStatus.Unknown);
    }
    return;
  };

  const handleSetName = async () => {
    if (!settingName) {
      setSettingName(true);
      console.log(
        `Attempting to set name and trigger deployment. (name = ${name})`
      );

      updateUser({ site: name })
        .then(() => {
          refetch();
        })
        .catch(console.error)
        .finally(() => {
          setSettingName(false);
        });
    }
  };

  if (user?.site) {
    return (
      <div>
        <div>
          A weboldalad:{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://${user.site}.webgen.hu`}
            className={styles.siteLink}
          >
            www.{user.site}.webgen.hu
          </a>
        </div>
        <div className={styles.deployButtonWrapper}>
          <Button
            className={styles.deployButton}
            onClick={() => {
              setTimeout(() => {
                setShouldPoll(true);
              }, 5000);
              setIsDeploying(true);
              deploySite(uid);
            }}
            loading={isDeploying}
            disabled={!templateId || isDeploying}
          >
            Weboldal közzététele
          </Button>
        </div>
      </div>
    );
  }

  const getInputSuffix = () => {
    if (!name) {
      return <div></div>;
    }

    if (nameStatus === NameStatus.Unknown) {
      return <Spinner size={15} thickness={3} />;
    }

    if (nameStatus === NameStatus.Available) {
      return <CheckCircleFilled className={styles.checkIcon} />;
    }

    return <CloseCircleFilled className={styles.xIcon} />;
  };

  return (
    <div className={styles.websiteNameSettings}>
      <div className={styles.sectionTitle}>
        Válassz egy nevet a weboldaladnak!
      </div>
      <div className={styles.sectionWarning}>
        Figyelem! A név egyedi és csak a Tiéd lesz. Megváltoztatni később NEM
        lehet.
      </div>
      <Input
        placeholder="Válassz nevet"
        className={styles.nameInput}
        value={name}
        onChange={(e: any) => onNameChange(e.target.value)}
        suffix={getInputSuffix()}
      />
      {name ? (
        <div>
          {nameStatus === NameStatus.Unknown ? (
            <div className={styles.spacingDiv}></div>
          ) : nameStatus === NameStatus.Available ? (
            <div className={styles.spacingDiv}>
              A(z) <p className={styles.siteName}>{name}</p> név még szabad!
              <div>
                Oldalad címe így nézne ki:
                <p className={styles.siteName}> www.{name}.webgen.hu</p>
              </div>
            </div>
          ) : (
            <div className={`${styles.spacingDiv} ${styles.takenText}`}>
              Ez a név már foglalt
            </div>
          )}
        </div>
      ) : (
        <div className={styles.spacingDiv}></div>
      )}
      <Button
        disabled={!name || nameStatus !== NameStatus.Available || settingName}
        onClick={handleSetName}
        className={styles.selectNameButton}
      >
        Név kiválasztása
      </Button>
    </div>
  );
};

export default withErrorBoundary(WebsiteName);
