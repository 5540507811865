import styles from "./style.module.css";
import Spinner from "../../LoadingSpinner";

type LargeButtonProps = {
  disabled: boolean;
  onClick: (e: any) => void | Promise<void>;
  isLoading?: boolean;
  text: string;
};

const LargeButton = ({
  disabled,
  onClick,
  isLoading,
  text,
}: LargeButtonProps) => (
  <button
    className={styles.largeButton}
    onClick={onClick}
    disabled={disabled}
  >
    {isLoading ? <Spinner size={25} /> : text}
  </button>
);

export default LargeButton;
