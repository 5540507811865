import { useState } from "react";
import { useLocation, Navigate } from "react-router-dom";
import { User } from "firebase/auth";
import AuthModal from "../../components/AuthModal";
import withErrorBoundary from "../../components/ErrorBoundary";

import "./style.css";

import { Button } from "antd";

const Landing = ({ user }: { user: User | undefined | null }) => {
  const location = useLocation();
  const [authModalVisible, setAuthModalVisible] = useState(false);

  if (location.pathname !== "/" && user === undefined) {
    return <Navigate to={"/"} replace />;
  }

  const toggleAuthModal = () => {
    setAuthModalVisible(!authModalVisible);
  };

  return (
    <div className="landing-root">
      <div>
        <div className="main-content">
          <div className="text-content">
            <div className="text-content-header">
              Turbózd fel a vállalkozásodat saját weboldallal!! 🚀
            </div>
            <div className="text-content-subtext">
              Szolgáltatásunkkal bárki könnyedén elkészítheti saját weboldalát,
              informatikai előképzettség nélkül! <br /> Előre elkészített,
              professzionális sablonok közül választhatsz, majd testre szabhatod
              azokat személyes igényeid szerint. Az elkészült weboldaladat pedig
              mindössze egy gombnyomással publikálhatod a saját címeden, amely
              csak pár percbe telik. Igen, tényleg ennyire egyszerű! Gyere, és
              próbáld ki te is!
            </div>
            <div className="auth-layout-buttons">
              {user ? (
                <div>
                  <a href="/dashboard">
                    <Button type="default" size="large" className="auth-button">
                      Irány a vezérlőpult! 🛠️
                    </Button>
                  </a>
                </div>
              ) : (
                <Button
                  type="default"
                  onClick={toggleAuthModal}
                  size="large"
                  className="auth-button"
                >
                  Bejelentkezés
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      <AuthModal
        isOpen={authModalVisible}
        onClose={() => setAuthModalVisible(false)}
      />
    </div>
  );
};

export default withErrorBoundary(Landing);
